import Layout from "../components/layout";
import React from "react";
import Seo from "../components/seo";
import { Link } from "gatsby";
import Container from "../components/container";

import * as styles from "../styles/components/404.module.css";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className={styles.root}>
      <Container>
        <div className={styles.containerContent}>
          <h1>404</h1>
          <p>Sorry, we can’t find that page. Please try again, or <Link to="/">visit the homepage</Link>.</p>
        </div>
      </Container>
    </div>
  </Layout>
);

export default NotFoundPage;
